<template>
  <div>
    <!--   Users   -->
    <tiles>
      <card-widget
        class="tile is-child"
        type="is-info"
        icon="account-multiple"
        :number="users.data.length"
        label="Count of Users"
      />
    </tiles>
  </div>
</template>

<script>
import Tiles from "@/components/Tiles";
import CardWidget from "@/components/CardWidget";
import {mapState} from "vuex";

export default {
  name: "UsersMetrics",
  components: {CardWidget, Tiles},
  computed: {
    ...mapState('user', [
      'users',
    ]),
  },
}
</script>

<style scoped>

</style>
