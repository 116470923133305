<template>
  <div class="card is-shadowless">
    <div class="card-content">
      <header class="mb-5">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <h2 class="is-size-6 has-text-dark has-text-weight-bold mr-3">Update User</h2>
          <div @click="$emit('close')">
            <b-icon class="cursor-pointer" icon="close-thick"/>
          </div>
        </div>
      </header>
      <section class="my-2">
        <ValidationObserver ref="form">
          <form autocomplete="off" @submit.prevent="handleSubmit">
            <div>
              <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                <b-field label="Email" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <b-input v-model="data.email"/>
                </b-field>
              </ValidationProvider>
              <ValidationProvider name="User Name" v-slot="{ errors }">
                <b-field label="User Name" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <b-input v-model="data.username"/>
                </b-field>
              </ValidationProvider>
              <ValidationProvider name="Phone" v-slot="{ errors }">
                <b-field label="Phone" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <b-input v-model="data.phone"/>
                </b-field>
              </ValidationProvider>
            </div>

            <b-button type="is-success" expanded class="has-text-weight-light mt-4" native-type="submit">
              Save
            </b-button>
          </form>
        </ValidationObserver>
      </section>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: "UpdateUser",
  props: {
    user: Object,
  },
  data: () => {
    return {
      data: {}
    }
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.resetData()
      }
    },
  },
  created() {
    this.resetData()
  },
  methods: {
    resetData() {
      this.data = cloneDeep(this.user)
    },

    ...mapActions('user', [
      'updateUser'
    ]),

    handleSubmit() {
      this.updateUser(this.data).then(() => {
        this.$emit('save')
        this.$emit('close')
      }).catch(err => {
        console.log(err)
      })
    },

    removeProduct(product_orders, key) {
      product_orders.splice(key, 1)
      this.handlePrice()
    },
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 600px !important;
  max-width: 100%;
}
</style>
