<template>
  <div>
    <title-bar :title-stack="titleStack"/>
    <section class="section is-main-section">
      <users-metrics class="mb-5"/>
      <users-table/>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import UsersMetrics from "@/components/Users/UsersMetrics";
import UsersTable from "@/components/Users/UsersTable";
import {mapActions} from "vuex";

export default {
  name: 'Users',
  components: {
    UsersTable,
    UsersMetrics,
    TitleBar
  },
  data: () => {
    return {
      activeTab: 'Metrics',
    }
  },
  computed: {
    titleStack() {
      return ['Users']
    }
  },
  created() {
    this.getUsers()
  },
  methods: {
    ...mapActions('user', [
      'getUsers',
    ]),
  }
}
</script>
