<template>
  <card-component>
    <b-table
      :loading="users.loading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      default-sort="username"
      :data='users.data'>

      <b-table-column label="Name" field="username" sortable v-slot="props" searchable>
        {{ props.row.username }}
      </b-table-column>
      <b-table-column label="Email" field="email" sortable v-slot="props" searchable>
        {{ props.row.email }}
      </b-table-column>
      <b-table-column label="Phone" field="phone" sortable v-slot="props" searchable>
        {{ props.row.phone }}
      </b-table-column>
      <b-table-column label="Action" v-slot="props">
        <div class="is-flex is-flex-direction-column">
          <!--          <b-button type="is-primary" @click="handleOpenDetails(props.row)" class="mb-2">Farm Detail</b-button>-->
          <b-button type="is-primary" @click="updateUser(props.row)" class="mb-2">Update User</b-button>
          <b-button type="is-danger" @click="destroyUser(props.row.user_id, props.row.username)">Delete User</b-button>
        </div>
      </b-table-column>

      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="users.loading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large"/>
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large"/>
            </p>
            <p>Nothing's there&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>

    <b-modal
      v-model="userModal"
      has-modal-card
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal>
      <template #default="props">
        <update-user @save="getUsers" @close="props.close" :user="selectedUser"/>
      </template>
    </b-modal>
  </card-component>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import cloneDeep from "lodash/cloneDeep";
import UpdateUser from "@/components/Book/UpdateUser";
import CardComponent from "@/components/CardComponent";

export default {
  name: 'UsersTable',
  components: {CardComponent, UpdateUser},
  data() {
    return {
      paginated: true,
      perPage: 10,
      selectedUser: null,
      userModal: false,
    }
  },
  computed: {
    ...mapState('user', [
      'users',
    ]),
  },
  methods: {
    ...mapActions('user', [
      'getUsers',
      'deleteUser',
    ]),

    updateUser(user) {
      this.selectedUser = cloneDeep(user)
      this.userModal = true
    },

    destroyUser(id, data_name) {
      this.$swal
        .fire({
          title: "Delete Selected User ?",
          html:
            "Your data " +
            data_name +
            " will permanently delete from database!",
          type: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes"
        })
        .then(result => {
          if (result.value) {
            this.deleteUser(id)
              .then(() => {
                this.getUsers()
              })
              .catch(() => {
              });
          }
        })
    },
  }
}
</script>
